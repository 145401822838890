import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import SideWideWarningBar from "../components/SideWideWarningBar";
import useSiteMetadata from "./SiteMetadata";
import { withPrefix } from "gatsby";
import { ThemeProvider, CSSReset, Box } from "@chakra-ui/core";
import { Global } from "@emotion/core";
import theme from "../theme";
import { jsx, css } from "@emotion/core";

const global = css`
  body {
    overflow-x: hidden;
  }

  .banner .gatsby-image-wrapper img {
    top: 100px !important;
  }
`;

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix("/")}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix("/")}img/og-image.jpg`}
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <CSSReset />
        <Global styles={global} />
        <Box width="100vw" overflow="hidden">
          {/* <SideWideWarningBar>
            Wij zijn gedurende de corona-crisis gewoon beschikbaar voor het
            afnemen van rijlessen.
          </SideWideWarningBar> */}
          <Navbar />
          <Box as="main" minHeight="calc(100vh + 100px)" mb="100px">
            {children}
          </Box>
          <Footer />
        </Box>
      </ThemeProvider>
    </>
  );
};

export default TemplateWrapper;
