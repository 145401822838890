import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

const PreviewCompatibleImage = ({ image, alt, ...rest }) => {
  const { childImageSharp, image: _image } = image || {};

  if (!!_image && !!_image.childImageSharp) {
    return <Img fluid={_image.childImageSharp.fluid} alt={alt} {...rest} />;
  }

  if (!!childImageSharp) {
    return <Img fluid={childImageSharp.fluid} alt={alt} {...rest} />;
  }

  if (!!_image && typeof _image === "string") {
    return <img src={_image} alt={alt} {...rest} />;
  }

  if (!!image && typeof image === "string") {
    return <img src={image} alt={alt} {...rest} />;
  }

  return null;
};

PreviewCompatibleImage.propTypes = {
  image: PropTypes.object,
  alt: PropTypes.string,
};

export default PreviewCompatibleImage;
