import React from "react";
import { theme } from "@chakra-ui/core";
const breakpoints = ["30em", "48em", "62em", "80em", "98em"];

// aliases
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];
breakpoints.xxl = breakpoints[4];

export default {
  ...theme,
  icons: {
    ...theme.icons,
    menu: {
      path: (
        <path
          fill="#000"
          d="M436 124H12c-6.627 0-12-5.373-12-12V80c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12z"
        />
      ),
      viewBox: "0 0 448 512"
    }
  },
  breakpoints,
  colors: {
    ...theme.colors,
    blue: {
      ...theme.colors.blue,
      500: "#3992ff"
    },
    orange: {
      ...theme.colors.orange,
      500: "#ff7700"
    }
  }
};
