import React from "react";
import { Link as _Link } from "gatsby";
import logo from "../img/logo.svg";
import {
  Box,
  PseudoBox,
  Flex,
  IconButton,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerBody,
  DrawerContent,
  Stack
} from "@chakra-ui/core";
import styled from "@emotion/styled";
import Container from "./Container";

const Logo = styled.img`
  height: 2.5rem;
`;

const NavigationBar = styled(Box)`
  display: block;
  position: relative;
  z-index: 10;
  background-color: #fff;
  border-top: 3px solid #3a91ff;
  background: #ffffff;
  box-shadow: 0 2px 8px 2px rgba(59, 59, 59, 0.12);
`;

const Navigation = styled(Box)`
  margin-left: 4rem;
  align-items: center;
`;

const Link = styled(_Link)`
  margin-right: 2rem;
  padding: 10px 0px;
  color: #44526e;

  &:hover,
  &:focus {
    color: #1f8fff;
  }
`;

const ContactButton = () => {
  return (
    <PseudoBox
      as={_Link}
      d="block"
      height="40px"
      lineHeight="40px"
      textAlign="center"
      transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
      px="18px"
      minWidth={130}
      rounded="40px"
      fontSize="14px"
      fontWeight="semibold"
      bg="#3A91FF"
      color="#fff"
      boxShadow="0 4px 3px 1px rgba(184,183,185,0.40)"
      _hover={{ bg: "#1A7EFD" }}
      _active={{
        bg: "#1A7EFD",
        transform: "scale(0.98)"
      }}
      _focus={{
        boxShadow:
          "0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)"
      }}
      to="/contact"
    >
      Contact
    </PseudoBox>
  );
};

const Navbar = () => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  const items = (
    <>
      <Link to="/" activeStyle={{ color: "#3b92ff", fontWeight: "bold" }}>
        Home
      </Link>
      <Link
        to="/over-ons"
        activeStyle={{ color: "#3b92ff", fontWeight: "bold" }}
      >
        Over ons
      </Link>
      <Link
        to="/proefles"
        activeStyle={{ color: "#3b92ff", fontWeight: "bold" }}
      >
        Proefles
      </Link>
      <Link
        to="/tarieven"
        activeStyle={{ color: "#3b92ff", fontWeight: "bold" }}
      >
        Tarieven
      </Link>
      <Link
        to="/theorie"
        activeStyle={{ color: "#3b92ff", fontWeight: "bold" }}
      >
        Theorie
      </Link>
    </>
  );

  return (
    <>
      <NavigationBar>
        <Container>
          <Flex h="5rem" align="center">
            <Logo src={logo} />
            <Navigation display={["none", null, null, "flex"]}>
              {items}
            </Navigation>
            <IconButton
              ml="auto"
              aria-label="Open menu"
              icon="menu"
              onClick={onToggle}
              display={["inline-flex", null, null, "none"]}
            />
            <Box ml="auto" display={["none", null, null, "block"]}>
              <ContactButton />
            </Box>
          </Flex>
        </Container>
      </NavigationBar>

      <Drawer size="xs" isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent borderTop="3px solid #1f8fff">
          <DrawerBody>
            <Stack>
              {items}
              <Box h={20} />
              <ContactButton />
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Navbar;
