import React from "react";

import logo from "../img/logo.svg";
import { Box, Flex } from "@chakra-ui/core";
import styled from "@emotion/styled";
import Container from "../components/Container";
import { Link } from "gatsby";

const Logo = styled("img")`
  height: 30px;
`;

const Footer = () => {
  return (
    <Box bg="#f7f7f7">
      <Container>
        <Flex h="70px" align="center" justifyContent="space-between">
          <Logo src={logo} />
          <Link to="/algemene-voorwaarden">Algemene voorwaarden</Link>
        </Flex>
      </Container>
    </Box>
  );
};

export default Footer;
